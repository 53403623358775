import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import AuditLogTab from '@portal/UI/components/AuditLogTab'

export default function AuditLogModal({
  open,
  label,
  onClose,
  maxWidth,
  smokeFeedbackId,
  tz,
}) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={{ sx: { minHeight: '700px' } }}
    >
      <DialogTitle sx={{ fontSize: '1.7rem' }}>{label}</DialogTitle>
      <DialogContent>
        <AuditLogTab
          excludeDateFilter
          objectId={smokeFeedbackId}
          filterDrawOpen={false}
          tz={tz}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

AuditLogModal.defaultProps = {
  maxWidth: 'lg',
  onClose: () => {},
}

AuditLogModal.propTypes = {
  label: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  smokeFeedbackId: PropTypes.string.isRequired,
  tz: PropTypes.string.isRequired,
}
