import { DateTime } from 'luxon'

import { createListBundle, isAbortError, parseApiErrors } from '@common/utils'

import propertyUrls from '../urls'
import propertyEventListData from './__fixtures__/propertyEventListData.json'

const entityName = 'propertyEvent'

const propertyEventListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params, store }) => {
    const property = store.selectCurrentProperty()

    let { startDate, endDate } = params

    if (!DateTime.isDateTime(startDate) || !DateTime.isDateTime(endDate)) {
      endDate = DateTime.now().setZone(property.timezone)
      startDate = endDate.minus({ days: 7 })
    }

    return apiFetch(
      '/events/',
      {
        localCreatedOnDateAfter: startDate.toISODate(),
        localCreatedOnDateBefore: endDate.toISODate(),
        property: store.selectRouteParams().id,
        eventClass: params.eventClass || 'SMOKE',
        pageSize: params?.pageSize,
        page: params?.page,
        ordering: params?.ordering,
        search: params?.search,
      },
      {
        mock: false,
        mockData: propertyEventListData,
        mockTimeout: 0,
        cancelationPrefix: entityName,
      },
    )
  },
  urlTest: (_, pattern) => pattern === propertyUrls.entity,
})

export default {
  ...propertyEventListBundle,
  doFetchPropertyChargeSummary:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectPropertyEventListApiParams()
      const propertyId = store.selectRouteParams().id

      const { startDate, endDate } = apiParams

      if (!DateTime.isDateTime(startDate) || !DateTime.isDateTime(endDate)) {
        return null
      }

      try {
        const response = await apiFetch(
          `/properties/${propertyId}/smoke_charge_summary/`,
          {
            start: startDate.startOf('day').toISO(),
            end: endDate.endOf('day').toISO(),
          },
          { cancelationPrefix: entityName },
        )
        return response
      } catch (err) {
        if (!isAbortError(err)) throw err
        return null
      }
    },
  doFetchPropertyEventSummary:
    (eventId, payload) =>
    async ({ apiFetch }) => {
      try {
        return await apiFetch(`/events/${eventId}/summary/`, payload, {
          cancelationPrefix: entityName,
        })
      } catch (err) {
        if (!isAbortError(err)) throw err
        return null
      }
    },
  doPropertyEventListExport:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectPropertyEventListApiParams()
      const propertyId = store.selectRouteParams().id
      const { count = 9999 } = store.selectPropertyEventList()
      try {
        const response = await apiFetch(
          '/events/',
          {
            ...apiParams,
            localCreatedOnDateAfter: apiParams.startDate.toISODate(),
            localCreatedOnDateBefore: apiParams.endDate.toISODate(),
            property: propertyId,
            pageSize: count,
          },
          { cancelationPrefix: entityName },
        )
        if (response.error) throw response.error
        return response.results
      } catch (error) {
        const parsedError = parseApiErrors(error?.response)
        store.doShowSnackbar(parsedError || 'Unable to export events', 'error')
      }
      return []
    },
}
