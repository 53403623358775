import { useCallback } from 'react'

import PropTypes from 'prop-types'

import { House as HouseIcon } from '@mui/icons-material'

import { AdvancedMarker } from '@vis.gl/react-google-maps'

export default function PropertyMarker({ property, onClick, setMarkerRef }) {
  const ref = useCallback(
    (marker) => setMarkerRef(marker, property.id),
    [setMarkerRef, property.id],
  )
  const handleClick = useCallback(() => onClick(property), [onClick, property])
  const hasCurrentOutage = Boolean(property.outageStart && !property.outageEnd)

  return (
    <AdvancedMarker
      key={property.id}
      ref={ref}
      position={{ lat: Number(property.latitude), lng: Number(property.longitude) }}
      onClick={handleClick}
    >
      <HouseIcon
        sx={{ fontSize: 30 }}
        color={hasCurrentOutage ? 'error' : 'secondary'}
      />
    </AdvancedMarker>
  )
}

PropertyMarker.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    outageStart: PropTypes.string,
    outageEnd: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  setMarkerRef: PropTypes.func.isRequired,
}
