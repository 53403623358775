import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import { Box } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'

import { noop } from '@common/utils'

export default function List({
  cellClick,
  checkbox,
  loading,
  columns,
  page,
  pageChange,
  pageSize,
  pageSizeChange,
  rows,
  rowCount,
  rowClick,
  sortChange,
  noValuePlaceholder,
  enableTopScrollbar,
  sx,
  ...dataGridProps
}) {
  const parentRef = useRef(null)
  const apiRef = useGridApiRef()
  const columnsAutosize = false
  const [internalRows, setInternalRows] = useState(rows ?? [])
  const [columnsSizes, setColumnsSizes] = useState(null)

  const autosizeOptions = useMemo(
    () => ({
      includeHeaders: true,
      includeOutliers: true,
      expand: true,
      columns: columns.map((colDef) => colDef.field),
    }),
    [columns],
  )

  useEffect(() => {
    if (!equals(rows, internalRows)) {
      setInternalRows(rows)
    }
  }, [rows])

  useEffect(() => {
    if (columnsAutosize && columnsSizes) {
      queueMicrotask(() => {
        apiRef.current.autosizeColumns(autosizeOptions)
      })
    }
  }, [autosizeOptions, columnsSizes, columnsAutosize])

  useEffect(() => {
    if (!enableTopScrollbar) {
      return () => {}
    }

    const scroller = parentRef?.current?.querySelector(
      `.MuiDataGrid-scrollbar--horizontal:not(.duplicated)`,
    )
    const targetContainer = parentRef.current?.querySelector('.top-scrollbar-container')
    const duplicatedTopScroller = scroller?.cloneNode(true)

    if (targetContainer?.childElementCount > 0) {
      targetContainer.innerHTML = ''
    }

    if (duplicatedTopScroller) {
      duplicatedTopScroller.style.top = 0
      duplicatedTopScroller.className += ' duplicated'
      targetContainer.style.height = '16px'
      targetContainer.appendChild(duplicatedTopScroller)
    }

    const syncScroll = () => {
      if (duplicatedTopScroller) {
        duplicatedTopScroller.scrollLeft = scroller.scrollLeft
      }
    }
    const syncScrollTop = () => {
      if (scroller) {
        scroller.scrollLeft = duplicatedTopScroller.scrollLeft
      }
    }

    scroller?.addEventListener('scroll', syncScroll)
    duplicatedTopScroller?.addEventListener('scroll', syncScrollTop)

    return () => {
      scroller?.removeEventListener('scroll', syncScroll)
      duplicatedTopScroller?.removeEventListener('scroll', syncScrollTop)
      if (targetContainer) {
        targetContainer.innerHTML = ''
      }
    }
  })

  const updateRows = useCallback(async () => {
    if (columnsAutosize) {
      if (
        !apiRef.current ||
        autosizeOptions.columns.some(
          (field) => !apiRef.current.state.columns.lookup[field],
        )
      ) {
        return
      }

      queueMicrotask(async () => {
        await apiRef.current.autosizeColumns(autosizeOptions)

        const columnsData = apiRef.current.state.columns.lookup
        const updatedSized = Object.keys(columnsData)
          .map((key) => {
            const colDef = columnsData[key]
            const width = colDef.computedWidth
            return { [key]: { width, hasBeenResized: colDef.hasBeenResized } }
          })
          .reduce((acc, item) => ({ ...acc, ...item }), {})
        setColumnsSizes(updatedSized)
      })
    }
  }, [autosizeOptions, internalRows, columnsAutosize])

  useEffect(() => {
    updateRows()
  }, [internalRows, autosizeOptions])

  let resizeTimer
  const handleWindowResize = () => {
    if (columnsAutosize) {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        updateRows()
      }, 250)
    }
  }

  const componentRef = useCallback(
    (node) => {
      if (!node || !columnsAutosize) return
      const resizeObserver = new ResizeObserver(() => {
        handleWindowResize()
      })
      resizeObserver.observe(node)
    },
    [autosizeOptions, columnsAutosize],
  )

  const handleSortChange = (sort) => {
    if (!sort.length) return sortChange(sort)
    const column = columns.find((col) => col.field === sort[0].field)
    const field = column.sortField ?? column.field
    return sortChange([field, sort[0].sort])
  }

  const onPaginationModelChange = (model) => {
    if (model.page !== page - 1) {
      pageChange(model.page + 1)
    }
    if (model.pageSize !== pageSize) {
      pageSizeChange(model.pageSize)
    }
  }

  const modifiedColumns = useMemo(
    () =>
      columns.map((column) => {
        let updateColDef = {
          display: column.renderCell ? 'flex' : 'block',
          valueGetter: (value) =>
            value === null || value === undefined || value === ''
              ? noValuePlaceholder
              : value,
          ...column,
        }
        if (columnsAutosize) {
          delete updateColDef.flex
          updateColDef = {
            ...updateColDef,
            width: columnsSizes?.[column.field]?.width ?? updateColDef.width,
          }
        }
        return updateColDef
      }),
    [noValuePlaceholder, columnsAutosize, columnsSizes],
  )

  return (
    <Box ref={parentRef} sx={{ position: 'relative' }}>
      <Box className="top-scrollbar-container" position="relative" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          '& .MuiDataGrid-root': {
            '--DataGrid-pinnedBackground': 'transparent !important',
            '--DataGrid-containerBackground': 'transparent !important',
          },
          '& .MuiDataGrid-container--top:after': { height: 0 },
          '& .MuiDataGrid-root, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeaders':
            { border: 'none' },
        }}
      >
        <DataGridPro
          disableColumnFilter
          disableRowSelectionOnClick
          apiRef={apiRef}
          ref={componentRef}
          checkboxSelection={checkbox}
          columns={modifiedColumns}
          density="comfortable"
          loading={loading}
          paginationModel={{ page: page - 1, pageSize }}
          onPaginationModelChange={onPaginationModelChange}
          pagination
          paginationMode="server"
          resizeThrottleMs={0}
          onCellClick={cellClick}
          sortingMode="server"
          onSortModelChange={handleSortChange}
          rows={rows}
          rowCount={rowCount}
          pageSizeOptions={[10, 25, 50, 100]}
          onRowClick={rowClick}
          sx={{
            '& .MuiDataGrid-columnSeparator': { display: 'none' },
            '& .MuiDataGrid-cell': { alignContent: 'center' },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
            '& .clickableCell': (theme) => ({
              cursor: 'pointer',
              color: 'primary.main',
              ...theme.typography.subtitle2,
            }),
            ...sx,
          }}
          {...dataGridProps}
        />
      </Box>
    </Box>
  )
}

List.defaultProps = {
  cellClick: noop,
  loading: false,
  checkbox: false,
  pageSize: 25,
  rowClick: noop,
  pageChange: noop,
  pageSizeChange: noop,
  sortChange: noop,
  noValuePlaceholder: '',
  columnsAutosize: false,
  enableTopScrollbar: false,
  sx: {},
}

List.propTypes = {
  cellClick: PropTypes.func,
  loading: PropTypes.bool,
  checkbox: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      width: PropTypes.number,
    }),
  ).isRequired,
  page: PropTypes.number.isRequired,
  pageChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeChange: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowCount: PropTypes.number.isRequired,
  rowClick: PropTypes.func,
  sortChange: PropTypes.func,
  noValuePlaceholder: PropTypes.string,
  columnsAutosize: PropTypes.bool,
  enableTopScrollbar: PropTypes.bool,
  sx: PropTypes.shape({}),
}
