import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, TextField } from '@mui/material'

import { parseApiErrors } from '@common/utils'
import IntegrationCard from '@portal/pages/Properties/Tabs/Integrations/Card'

export default function ReviewIngestCard({
  property,
  saveProperty,
  fetchProperty,
  showSnackBar,
  isAtLeastAdmin,
}) {
  const [ids, setIds] = useState({
    tripadvisor: property?.tripadvisorId,
    googleMaps: property?.googlePlaceId,
  })

  useEffect(() => {
    if (property) {
      setIds({
        tripadvisor: property?.tripadvisorId,
        googleMaps: property?.googlePlaceId,
      })
    }
  }, [property])

  const fieldDirty =
    property?.tripadvisorId !== ids.tripadvisor ||
    property?.googlePlaceId !== ids.googleMaps

  const isEnabled = !!property?.tripadvisorId || !!property?.googlePlaceId

  const handleIngestSave = async (type) => {
    try {
      const response = await saveProperty({
        ...property,
        tripadvisorId: type === 'disable' ? '' : ids.tripadvisor,
        googlePlaceId: type === 'disable' ? '' : ids.googleMaps,
      })
      if (response?.id) {
        showSnackBar('Review ingestion updated successfully.')
        fetchProperty(property?.id)
      }
    } catch (err) {
      const parsedError = parseApiErrors(err)
      showSnackBar(parsedError, 'error')
    }
  }

  return (
    <IntegrationCard
      title="Review Ingest"
      subheader={`Manage review ingestion integration for ${property?.name}.`}
      enabled={isEnabled}
      cardStyles={{ width: 420 }}
      content={
        <Box display="flex" flexDirection="column" with="100%" gap={2}>
          <TextField
            variant="standard"
            name="tripadvisorId"
            label="Tripadvisor ID"
            value={ids.tripadvisor}
            disabled={!isAtLeastAdmin}
            onChange={(e) => {
              setIds((prev) => ({ ...prev, tripadvisor: e.target.value.trim() }))
            }}
          />
          <TextField
            variant="standard"
            name="googlePlaceId"
            label="Google Maps ID"
            value={ids.googleMaps}
            disabled={!isAtLeastAdmin}
            onChange={(e) => {
              setIds((prev) => ({ ...prev, googleMaps: e.target.value.trim() }))
            }}
          />
        </Box>
      }
      actions={
        <Box width="100%" display="flex" justifyContent="space-between">
          <Button
            color="error"
            disabled={!isAtLeastAdmin}
            onClick={() => handleIngestSave('disable')}
            sx={{
              visibility: isEnabled ? 'visible' : 'hidden',
            }}
          >
            Disable
          </Button>
          <Button
            color="secondary"
            disabled={!fieldDirty || !isAtLeastAdmin}
            onClick={() => handleIngestSave()}
          >
            {isEnabled ? 'Update' : 'Enable'}
          </Button>
        </Box>
      }
    />
  )
}

ReviewIngestCard.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tripadvisorId: PropTypes.string,
    googlePlaceId: PropTypes.string,
  }).isRequired,
  saveProperty: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  showSnackBar: PropTypes.func.isRequired,
  isAtLeastAdmin: PropTypes.bool.isRequired,
}
