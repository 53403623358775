import { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Alert, Box, TextField } from '@mui/material'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { TabComponent } from '@common/components'

import RateCodeTab from './RateCodeTab'

export default function AutochargeParamsForm({
  property,
  setApiValues,
  setFormIsDirty,
  setFormikProps,
  apiErrors,
  integrationType,
  setSubmittingDisabled,
}) {
  const [tabValue, setTabValue] = useState(0)
  const { autochargeParamsSchema } = useConnect('selectAutochargeParamsSchema')

  const validatedApiErrors = useMemo(() => apiErrors, [apiErrors])

  const currentSchema = autochargeParamsSchema.find((s) => s.key === integrationType)
  const schemaFields = currentSchema?.schema?.map((field) => field.name)

  const getFieldPayload = (field) => currentSchema.schema.find((f) => f.name === field)
  const getLabel = (field) => {
    const { label, required } = getFieldPayload(field)
    return required ? `${label} *` : label
  }
  const isNumberField = (field) =>
    ['int', 'float'].includes(getFieldPayload(field)?.kind)

  // reset the form fields on integration change to mitigate
  // stale state when 2 integrations have the same attribute names
  const initialAutochargeParams = schemaFields?.reduce((acc, field) => {
    const integrationHasChanged = integrationType !== property?.smokeAutocharge
    const initialFieldValue = property?.smokeAutochargeParams?.[field] || ''
    const defaultFieldValue = getFieldPayload(field)?.default

    acc[field] = integrationHasChanged ? defaultFieldValue : initialFieldValue
    return acc
  }, {})

  const initialValues = {
    smokeAutocharge: integrationType || '',
    smokeAutochargeParams: {
      ...initialAutochargeParams,
      rateCodes: property?.smokeAutochargeParams?.rateCodes || {},
    },
  }

  const getFieldSchema = (field) => {
    const requiredMsg = `${field.label} is required.`

    if (isNumberField(field)) {
      return field.required ? yup.number().required(requiredMsg) : yup.number()
    }
    return field.required ? yup.string().required(requiredMsg) : yup.string()
  }

  const autochargeParamsValidationSchema = currentSchema?.schema.reduce(
    (acc, field) => {
      acc[field.name] = getFieldSchema(field)
      return acc
    },
    {},
  )

  const validationSchema = yup.object({
    smokeAutocharge: yup.string(),
    smokeAutochargeParams: yup.object({
      ...autochargeParamsValidationSchema,
      rateCodes: yup.object({}),
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  })
  const {
    handleChange,
    validateField,
    resetForm,
    setValues,
    setErrors,
    values,
    errors,
    dirty,
  } = formik

  const getHelperText = (field) => {
    const apiFieldErrors =
      validatedApiErrors?.smokeAutochargeParams?.[field]?.join(`\n`)
    const formikFieldError = errors?.smokeAutochargeParams?.[field]

    const formatErrors = apiFieldErrors || formikFieldError
    const helpText = getFieldPayload(field)?.helpText
    return formatErrors || helpText
  }

  useEffect(() => {
    setFormikProps(formik)
  }, [formik?.values, formik?.errors, formik?.dirty])

  const handleFieldChange = async (event, field) => {
    await handleChange(event)
    await validateField(`smokeAutochargeParams.${field}`)

    delete validatedApiErrors?.smokeAutochargeParams?.[field]
  }

  const fieldHasError = (field) =>
    Boolean(validatedApiErrors?.smokeAutochargeParams?.[field]) ||
    Boolean(errors?.smokeAutochargeParams?.[field])

  useEffect(() => {
    resetForm({ values: initialValues })
  }, [integrationType])

  useEffect(() => {
    setErrors(validatedApiErrors)
  }, [validatedApiErrors])

  useEffect(() => {
    const { nonFieldErrors, ...fieldErrors } = errors
    const hasErrors =
      isEmpty(fieldErrors) ||
      Object.values(fieldErrors).every((field) => isEmpty(field))
    setSubmittingDisabled(!hasErrors)
  }, [errors])

  useEffect(() => {
    setApiValues(values)
    setFormIsDirty(dirty)
  }, [values])

  useEffect(() => () => setApiValues(initialValues), [])

  const tabs = [
    {
      label: 'Params',
      component: (
        <Box display="flex" flexDirection="column" gap={2} my={1}>
          {Object.keys(initialValues.smokeAutochargeParams)
            .filter((param) => param !== 'rateCodes')
            .map((field) => (
              <TextField
                key={field}
                type={isNumberField(field) ? 'number' : 'text'}
                variant="standard"
                onChange={(event) => handleFieldChange(event, field)}
                label={getLabel(field)}
                name={`smokeAutochargeParams.${field}`}
                value={values.smokeAutochargeParams?.[field] ?? ''}
                error={fieldHasError(field)}
                helperText={getHelperText(field)}
              />
            ))}
        </Box>
      ),
    },
    {
      label: 'Rate Codes',
      component: <RateCodeTab values={values} setValues={setValues} />,
    },
  ]

  return (
    <>
      {validatedApiErrors?.nonFieldErrors?.map((error) => (
        <Alert key={error} severity="error">
          {error}
        </Alert>
      ))}
      <TabComponent
        tabs={tabs}
        variant="fullWidth"
        externalState={{ value: tabValue, setValue: setTabValue }}
      />
    </>
  )
}

AutochargeParamsForm.propTypes = {
  property: PropTypes.shape({
    smokeAutocharge: PropTypes.string,
    smokeAutochargeParams: PropTypes.shape({
      rateCodes: PropTypes.shape({}),
    }),
  }).isRequired,
  setApiValues: PropTypes.func.isRequired,
  setFormIsDirty: PropTypes.func.isRequired,
  setFormikProps: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape({
    smokeAutochargeParams: PropTypes.shape({}),
  }).isRequired,
  integrationType: PropTypes.string.isRequired,
  setSubmittingDisabled: PropTypes.func.isRequired,
}
