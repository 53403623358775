import { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box, Typography } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'

export default function PropertyWifiForm({ open, onClose, instance }) {
  const { doShowSnackbar, doPropertySave, propertySaveStatus } = useConnect(
    'doShowSnackbar',
    'doPropertySave',
    'selectPropertySaveStatus',
  )

  const validationSchema = yup.object().shape({
    currentWifiSsid: yup
      .string()
      .max(50)
      .when('currentWifiPw', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required('Ssid is required if password is provided'),
      }),
    currentWifiPw: yup.string().max(63),
    desiredWifiSsid: yup
      .string()
      .max(50)
      .when('desiredWifiPw', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required('Ssid is required if password is provided'),
      }),
    desiredWifiPw: yup.string().max(63),
  })

  const initialValues = {
    id: '',
    currentWifiSsid: '',
    currentWifiPw: '',
    desiredWifiSsid: '',
    desiredWifiPw: '',
  }

  if (instance?.id) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const updateProperty = async (params) => {
    try {
      const saveResult = await doPropertySave({
        ...instance,
        ...params,
      })

      return saveResult
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
      throw err
    }
  }

  const CredentialsRow = useCallback(({ label, ssidField, pwField, disabled, mt }) => {
    const sharedStyles = { flex: 1 }

    return (
      <>
        <Typography
          variant="body2"
          color="text.secondary"
          textTransform="uppercase"
          mt={mt}
        >
          {label}
        </Typography>
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <Field
            component={TextField}
            label="SSID"
            name={ssidField}
            disabled={disabled}
            sx={sharedStyles}
          />
          <Field
            component={TextField}
            label="Password"
            name={pwField}
            disabled={disabled}
            sx={sharedStyles}
          />
        </Box>
      </>
    )
  }, [])

  return (
    <FormDialog
      label="Wifi Configuration"
      open={open}
      onSave={updateProperty}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={propertySaveStatus === 'loading'}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <CredentialsRow
          disabled
          label="Current Wi-Fi Credentials"
          ssidField="currentWifiSsid"
          pwField="currentWifiPw"
        />
        <CredentialsRow
          label="Desired Wi-Fi Credentials"
          ssidField="desiredWifiSsid"
          pwField="desiredWifiPw"
          mt={3}
        />
      </Box>
    </FormDialog>
  )
}

PropertyWifiForm.defaultProps = {
  instance: {},
}

PropertyWifiForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string.isRequired,
    currentWifiSsid: PropTypes.string.isRequired,
    currentWifiPw: PropTypes.string.isRequired,
    desiredWifiSsid: PropTypes.string.isRequired,
    desiredWifiPw: PropTypes.string.isRequired,
  }),
}
