import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box, Rating, Tooltip } from '@mui/material'

import { humanize } from 'inflection'
import { DateTime } from 'luxon'

import { MobileList, MobileListDefaultCard } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import GoogleMaps from '@common/icons/GoogleMaps'
import TripAdvisor from '@common/icons/TripAdvisor'
import { useSmallScreen } from '@common/utils'
import { GridCellExpand } from '@portal/UI/components/cells'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import Picker from '@portal/UI/components/Picker'

const sources = ['TRIPADVISOR', 'GOOGLE_MAPS']

export default function Reviews({ property }) {
  const isSmallScreen = useSmallScreen()

  const {
    doPropertyReviewListClearParams,
    doPropertyReviewListSetFilter,
    doPropertyReviewListSetPage,
    doPropertyReviewListSetPageSize,
    doPropertyReviewListSetOrdering,
    doPropertyReviewListSetSearch,
    propertyReviewList,
    propertyReviewListRaw: { ordering = [] },
    propertyReviewListIsLoading,
    propertyReviewListApiParams,
  } = useConnect(
    'doPropertyReviewListClearParams',
    'doPropertyReviewListSetFilter',
    'doPropertyReviewListSetPage',
    'doPropertyReviewListSetPageSize',
    'doPropertyReviewListSetOrdering',
    'doPropertyReviewListSetSearch',
    'selectPropertyReviewList',
    'selectPropertyReviewListRaw',
    'selectPropertyReviewListIsLoading',
    'selectPropertyReviewListApiParams',
  )

  const [filterModalOpen, setFilterModalOpen] = useState(false)

  useEffect(() => {
    doPropertyReviewListClearParams()
  }, [])

  const sourceOptions = useMemo(
    () => sources.map((source) => ({ id: source, label: humanize(source) })),
    [],
  )

  const columns = [
    {
      field: 'author',
      headerName: 'Author',
      flex: 0.4,
      sortable: false,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      sortable: false,
      renderCell: GridCellExpand,
      renderMobile: ({ row }) => row.comment,
      maxWidth: 600,
    },
    {
      field: 'publishedOn',
      headerName: 'Published On',
      valueFormatter: (value) =>
        DateTime.fromISO(value, { zone: property?.timezone }).toLocaleString(
          DateTime.DATETIME_MED,
        ),
      flex: 0.3,
    },
    {
      field: 'source',
      headerName: 'Source',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => {
        if (row.source === 'GOOGLE_MAPS') {
          return (
            <Tooltip title="Google Maps">
              <span>
                <GoogleMaps />
              </span>
            </Tooltip>
          )
        }
        if (row.source === 'TRIPADVISOR') {
          return (
            <Tooltip title="Tripadvisor">
              <span>
                <TripAdvisor />
              </span>
            </Tooltip>
          )
        }
        return humanize(row.source)
      },
      flex: 0.2,
    },
    {
      field: 'rating',
      headerName: 'Rating',
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) => (
        <Tooltip title={row.starRating}>
          <span>
            <Rating
              readOnly
              value={row.starRating}
              precision={0.25}
              max={row.maxStarRating}
            />
          </span>
        </Tooltip>
      ),
      flex: 0.3,
    },
  ]

  const rows = propertyReviewList?.results?.map((review) => ({
    id: review.id,
    author: review.author,
    comment: review.comment,
    property: review.property,
    propertyName: review.propertyName,
    publishedOn: review.publishedOn,
    rating: review.rating,
    source: review.source,
    starRating: review.starRating,
    maxStarRating: review.maxStarRating,
  }))

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '900px',
      }}
    >
      <Box display="flex">
        <Filter
          mode={isSmallScreen ? 'modal' : 'drawer'}
          disabled={propertyReviewListIsLoading}
          apiParams={propertyReviewListApiParams}
          setApiParams={doPropertyReviewListSetFilter}
          dialogOpen={filterModalOpen}
          dialogOnClose={() => setFilterModalOpen(false)}
        >
          <StaticSelect label="Source" filterName="source" options={sourceOptions} />
          <Picker
            range
            type="date"
            label="Published on"
            disableMaskedInput
            filterName="publishedOn"
            conditionSeparator=""
            lowerCondition="After"
            upperCondition="Before"
            value={propertyReviewListApiParams?.publishedOn}
          />
        </Filter>
        <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
          {isSmallScreen ? (
            <MobileList
              title="Reviews"
              loading={propertyReviewListIsLoading}
              showActions={false}
              itemBuilder={MobileListItem}
              onFilterPressed={() => setFilterModalOpen(true)}
              page={propertyReviewList?.current || 1}
              pageChange={doPropertyReviewListSetPage}
              pageSize={propertyReviewList?.pageSize}
              pageSizeChange={doPropertyReviewListSetPageSize}
              rows={rows}
              rowCount={propertyReviewList?.count || 0}
              setSearch={doPropertyReviewListSetSearch}
            />
          ) : (
            <List
              columnsAutosize
              title="Reviews"
              loading={propertyReviewListIsLoading}
              showActions={false}
              showAddButton={false}
              columns={columns}
              page={propertyReviewList?.current || 1}
              pageChange={doPropertyReviewListSetPage}
              pageSize={propertyReviewList?.pageSize}
              pageSizeChange={doPropertyReviewListSetPageSize}
              rows={rows}
              rowCount={propertyReviewList?.count || 0}
              setSearch={doPropertyReviewListSetSearch}
              sortChange={doPropertyReviewListSetOrdering}
              currentOrdering={ordering}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

Reviews.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
  }).isRequired,
}
