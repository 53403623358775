import { path } from 'ramda'

import { isAbortError } from '@common/utils'

const EVENT_REPORT_DATA_LOADING = 'EVENT_REPORT_DATA_LOADING'
const EVENT_REPORT_DATA_LOADED = 'EVENT_REPORT_DATA_LOADED'
const EVENT_REPORT_DATA_FAILED = 'EVENT_REPORT_DATA_FAILED'

const EVENT_REPORT_RESET_STATE = 'EVENT_REPORT_RESET_STATE'

const defaultState = {}

const entityName = 'eventReport'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === EVENT_REPORT_RESET_STATE) {
      return defaultState
    }
    if (action.type === EVENT_REPORT_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === EVENT_REPORT_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === EVENT_REPORT_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectEventReportIsLoading: ({ eventReport }) => {
    const status = path(['data', 'status'], eventReport)
    return status === 'loading'
  },
  selectEventReport: ({ eventReport }) => path(['data', 'data'], eventReport),
  selectEventReportError: ({ eventReport }) => path(['data', 'error'], eventReport),
  doResetEventReportState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: EVENT_REPORT_RESET_STATE }),
  doFetchEventReport:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: EVENT_REPORT_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { id, device, property, unit } = payload

        const apiResults = await Promise.all([
          apiFetch(`/properties/${property}/`, null, { cancelationPrefix: entityName }),
          apiFetch(`/devices/${device}/`, null, { cancelationPrefix: entityName }),
          apiFetch(`/events/${id}/data_quality_report/`, null, {
            cancelationPrefix: entityName,
          }),
          apiFetch(
            `/installations/`,
            {
              unit,
              ordering: '-createdOn',
              pageSize: 2,
            },
            { cancelationPrefix: entityName },
          ),
        ])

        const propertyResponse = apiResults[0]
        const deviceResponse = apiResults[1]
        const reportResponse = apiResults[2]
        const installationsResponse = apiResults[3]

        const photosUrls =
          installationsResponse?.results
            ?.filter((item) => item.deviceMac === deviceResponse?.mainMac)
            ?.map((item) => item.photoUrl) ?? []

        const data = {
          property: propertyResponse,
          device: { ...deviceResponse, photos: photosUrls },
          report: reportResponse,
        }

        dispatch({
          type: EVENT_REPORT_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: EVENT_REPORT_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
