import { useCallback } from 'react'

import PropTypes from 'prop-types'

import { Box, Divider, Stack, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import orgUrls from '@portal/pages/Organizations/urls'
import userUrls from '@portal/pages/Users/urls'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

function DeviceCount({ label, value }) {
  const isSmallScreen = useSmallScreen()

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant="body2"
        color="text.secondary"
        textTransform="uppercase"
        sx={{ fontSize: isSmallScreen ? 10 : 13 }}
      >
        {label}
      </Typography>
      <Typography variant="caption">{value || 0}</Typography>
    </Box>
  )
}

DeviceCount.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default function DetailsComponent({ property, margin, gap }) {
  const isSmallScreen = useSmallScreen()

  const buildAddressText = useCallback(() => {
    const addressArr = []
    const locationArr = []
    if (property?.address1) addressArr.push(property.address1)
    if (property?.address2) addressArr.push(property.address2)
    if (property?.city) locationArr.push(property.city)
    if (property?.state) locationArr.push(property.state)
    if (property?.zipCode) locationArr.push(property.zipCode)

    if (!addressArr.length && !locationArr.length) {
      return null
    }

    return [addressArr.join(', '), locationArr.join(' ')].join(', ')
  }, [property])

  return (
    <DetailContainer margin={margin} gap={gap}>
      <DetailItem
        label="CSM Owner"
        value={property?.csmOwnerName}
        linkTo={userUrls.entity.replace(':id', property?.csmOwnerId)}
      />
      <DetailItem
        label="Organization"
        value={property?.organizationName}
        linkTo={orgUrls.entity.replace(':id', property?.organization)}
      />
      <DetailItem
        label="Account"
        value={property?.accountName}
        linkTo={accountUrls.entity.replace(':id', property?.account)}
      />
      {property?.groupName && <DetailItem label="Group" value={property?.groupName} />}
      <DetailItem label="Timezone" value={property?.timezone} />
      <DetailItem label="Total Units" value={property?.unitCount} />
      <DetailItem
        label="Total Devices"
        value={property}
        renderValue={(value) => (
          <Box width="fit-content">
            <Typography
              variant="h6"
              whiteSpace="pre-line"
              sx={{ fontSize: isSmallScreen ? 14 : 20 }}
            >
              {value?.deviceCount || 0}
            </Typography>
            {value?.deviceCount > 0 && (
              <>
                <Divider />
                <Stack spacing={3} direction="row">
                  <DeviceCount label="Online" value={value?.onlineSensorCount} />
                  <DeviceCount label="Offline" value={value?.offlineSensorCount} />
                  <DeviceCount label="Installed" value={value?.installedSensorCount} />
                  <DeviceCount label="RMA" value={value?.rmaSensorCount} />
                  <DeviceCount
                    label="RMA In Progress"
                    value={value?.rmaInProgressSensorCount}
                  />
                  <DeviceCount label="Shipped" value={value?.shippedSensorCount} />
                  <DeviceCount label="Other" value={value?.otherSensorCount} />
                </Stack>
              </>
            )}
          </Box>
        )}
      />
      <DetailItem label="Address" value={buildAddressText()} />
    </DetailContainer>
  )
}

DetailsComponent.defaultProps = {
  margin: 0,
  gap: 0,
}

DetailsComponent.propTypes = {
  property: PropTypes.shape({
    csmOwnerName: PropTypes.string,
    csmOwnerId: PropTypes.string,
    organization: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    groupName: PropTypes.string,
    timezone: PropTypes.string,
    unitCount: PropTypes.number,
    deviceCount: PropTypes.number,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  margin: PropTypes.number,
  gap: PropTypes.number,
}
