import PropTypes from 'prop-types'

import { AxisBottom, AxisLeft } from '@visx/axis'
import { Group } from '@visx/group'
import { ParentSize } from '@visx/responsive'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Bar } from '@visx/shape'

const AQI_MIN = 0
const AQI_MAX = 500

const basicStokeStyle = {
  tickStroke: 'none',
  strokeWidth: 4,
  stroke: '#f0f1f4',
}

const basicAxisTextStyle = {
  fontSize: 8,
  fontFamily: 'Degular',
}

const leftAxisTicks = {
  50: 'good',
  100: 'moderate',
  200: 'unhealthy',
  300: 'very\nunhealthy',
  500: 'hazardous',
}

export const PRIOR_AQI_X = 'prior 12 hours'
export const EVENT_AQI_X = 'during smoking event'

function AqiChart({ data }) {
  const categories = [...new Set(data.map((d) => d.x))]
  const padding = { top: 14, bottom: 20, left: 1, right: 0 }

  const xScale = scaleBand({
    domain: categories,
    paddingInner: 0.6,
    paddingOuter: 0.8,
    align: 0.7,
  })
  const yScale = scaleLinear({
    domain: [AQI_MIN, AQI_MAX],
  })

  return (
    <div style={{ height: '190px', width: '100%' }}>
      <ParentSize>
        {({ width, height }) => {
          const [xMin, xMax] = [padding.left, width - padding.right]
          const [yMin, yMax] = [height - padding.bottom, padding.top]

          xScale.range([xMin, xMax])
          yScale.range([yMin, yMax])

          return (
            <svg width={width} height={height}>
              <AxisLeft
                {...basicStokeStyle}
                scale={yScale}
                left={padding.left}
                orientation="right"
                rangePadding={{ end: -25 }}
                tickValues={Object.keys(leftAxisTicks)}
                tickLabelProps={() => ({
                  ...basicAxisTextStyle,
                  fontWeight: 600,
                  fill: '#9ba1a8',
                  textAnchor: 'start',
                  dominantBaseline: 'middle',
                })}
                tickFormat={(tick) =>
                  Object.entries(leftAxisTicks).find(
                    ([key]) => `${key}` === `${tick}`,
                  )?.[1] ?? tick
                }
              />
              <Group>
                <defs>
                  <linearGradient id="bar-fill" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop
                      offset="25%"
                      style={{ stopColor: '#d96262', stopOpacity: 1 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: 'orange', stopOpacity: 1 }}
                    />
                    <stop
                      offset="80%"
                      style={{ stopColor: 'yellow', stopOpacity: 1 }}
                    />
                    <stop
                      offset="95%"
                      style={{ stopColor: '#bcefd8', stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
                {data.map((bar) => {
                  const barWidth = xScale.bandwidth()
                  const barHeight = Math.abs(yScale(bar.y) - yScale(0))

                  const barX = xScale(bar.x)
                  const barY = yScale(bar.y)

                  return (
                    <g key={`${bar.x},${bar.y}`}>
                      <Bar
                        x={barX}
                        y={padding.top}
                        width={barWidth}
                        height={Math.abs(yScale(AQI_MAX) - yScale(0))}
                        fill="url(#bar-fill)"
                        clipPath={`inset(${100 - (bar.y * 100) / AQI_MAX}% 0 0 0)`}
                      />
                      <Bar
                        x={barX}
                        y={barY}
                        width={barWidth}
                        height={barHeight}
                        fill="transparent"
                      />
                      {barHeight !== 0 && bar.x !== EVENT_AQI_X && (
                        <text
                          x={barX + barWidth / 2}
                          y={barY - 12}
                          fill="black"
                          textAnchor="middle"
                          dominantBaseline="hanging"
                          fontSize={8}
                          fontWeight={600}
                          fontFamily="Degular"
                          color="#252e4b"
                        >
                          {bar.y}
                        </text>
                      )}
                    </g>
                  )
                })}
              </Group>
              <AxisBottom
                {...basicStokeStyle}
                scale={xScale}
                top={height - padding.bottom}
                orientation="bottom"
                tickLabelProps={() => ({
                  ...basicAxisTextStyle,
                  fontWeight: 600,
                  textAnchor: 'middle',
                  fill: '#252e4b',
                })}
              />
            </svg>
          )
        }}
      </ParentSize>
    </div>
  )
}

AqiChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number }))
    .isRequired,
}

export default AqiChart
