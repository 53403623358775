import { useCallback, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Animation as AnimationIcon } from '@mui/icons-material'
import { Box, Link, ThemeProvider, Tooltip, Typography } from '@mui/material'

import { capitalize } from 'inflection'
import { DateTime } from 'luxon'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  ListTextTooltipWrapper,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { humanizedAgo, useQueryFilter, useSmallScreen } from '@common/utils'
import EventViewModal from '@portal/pages/EventDashboard/EventModal/EventViewModal'
import { homeUrls } from '@portal/pages/Home'
import orgUrls from '@portal/pages/Organizations/urls'
import propertyUrls from '@portal/pages/Properties/urls'
import unitUrls from '@portal/pages/Units/urls'
import BulkPopover from '@portal/UI/components/BulkPopover'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListExportButton from '@portal/UI/components/ListExportButton'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import { darkTheme } from '@portal/UI/Theme'
import boolOptions from '@portal/Utils/constants'

import ReassignForm from './ReassignForm'
import SimulatedDeviceForm from './SimulatedDeviceForm'
import CommandForm from './Tabs/Command/CommandForm'
import deviceUrls from './urls'

export default function DeviceList({ renderAsTab, unitId, propertyId }) {
  const [pageSize, setPageSize] = useState('')
  const [saveFormOpen, setSaveFormOpen] = useState(false)
  const [popoverLocation, setPopoverLocation] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [currentDevice, setCurrentDevice] = useState({})
  const [reassignFormOpen, setReassignFormOpen] = useState(false)
  const [simulatedDeviceFormOpen, setSimulatedDeviceFormOpen] = useState(false)
  const [commandFormOpen, setCommandFormOpen] = useState(false)
  const [unassignConfirmationOpen, setUnassignConfirmationOpen] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [grafanaViewDevice, setGrafanaViewDevice] = useState(null)

  const {
    doMarkDeviceListAsOutdated,
    doDeviceListSetPageSize,
    doDeviceListSetPage,
    doDeviceListSetOrdering,
    doDeviceListSetFilter,
    doDeviceListClearParams,
    doDeviceSendCommand,
    doDeviceSave,
    doDeviceUnassign,
    deviceList,
    deviceListRaw: { ordering = [] },
    deviceListIsLoading,
    doUpdateUrl,
    doUpdateQuery,
    doShowSnackbar,
    systemDeviceStatus,
    systemDeviceHealthStatus,
    systemDeviceModels,
    deviceListApiParams,
    queryObject,
  } = useConnect(
    'doMarkDeviceListAsOutdated',
    'doDeviceListSetPageSize',
    'doDeviceListSetPage',
    'doDeviceListSetOrdering',
    'doDeviceListSetFilter',
    'doDeviceListClearParams',
    'doDeviceSendCommand',
    'doDeviceSave',
    'doDeviceUnassign',
    'selectDeviceList',
    'selectDeviceListRaw',
    'selectDeviceListIsLoading',
    'doUpdateUrl',
    'doUpdateQuery',
    'doShowSnackbar',
    'selectSystemDeviceStatus',
    'selectSystemDeviceHealthStatus',
    'selectSystemDeviceModels',
    'selectDeviceListApiParams',
    'selectQueryObject',
  )

  const queryHolder = useRef(null)

  useEffect(() => {
    if (queryObject?.grafanaDevice) {
      setGrafanaViewDevice(queryObject.grafanaDevice)
    }
  }, [queryObject])

  const isSmallScreen = useSmallScreen()

  const initialParams = {
    model: systemDeviceModels?.find((m) => m.label === 'sg0ib')?.id,
  }

  useQueryFilter({
    query: queryObject,
    defaultParams: initialParams,
    apiParams: deviceListApiParams,
    setFilter: doDeviceListSetFilter,
    setPageSize: doDeviceListSetPageSize,
    ignoreIf: (query) => query.grafanaDevice,
  })

  const handleClear = () => {
    doDeviceListClearParams()
    doDeviceListSetFilter('')
  }

  const handlePageChange = async (page) => {
    doDeviceListSetPage(page)
  }

  const handleRowClick = (params) => {
    const updatedId = deviceUrls.entity.replace(':id', params.id)
    doUpdateUrl(updatedId)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doDeviceListSetPageSize(size)
  }

  const openBulkUpdate = (_, anchorEl) => {
    setSaveFormOpen(true)
    setPopoverLocation(anchorEl)
  }

  const openBulkSendCommand = () => {
    setCommandFormOpen(true)
  }

  const openBulkUnassign = () => {
    setUnassignConfirmationOpen(true)
  }

  const handleClose = () => {
    setSaveFormOpen(false)
    setPopoverLocation(null)
    setSelectedIds([])
  }

  const handleBulkUpdate = (status) => async () => {
    const payload = selectedIds.map((id) => ({ id, status }))
    const response = await doDeviceSave(payload)
    if (response.error) {
      doShowSnackbar('Error in updating devices', 'error')
    } else {
      doShowSnackbar('Successfully updated devices', 'success')
      doMarkDeviceListAsOutdated()
      handleClose()
    }
  }

  const openEditForm = (row) => {
    setCurrentDevice(row)
    if (row.simulatedInterval) {
      setSimulatedDeviceFormOpen(true)
    } else {
      setReassignFormOpen(true)
    }
  }

  const handleUnassignDevice = async () => {
    const payload = selectedIds.map((id) => ({ id }))
    const response = await doDeviceUnassign(payload)
    if (response?.error) {
      doShowSnackbar(`An unexpected error occurred`, 'error')
      return
    }
    doShowSnackbar(`Successfully unassigned devices`, 'success')
    setSelectedIds([])
    doMarkDeviceListAsOutdated()
    setUnassignConfirmationOpen(false)
  }

  const getDeviceModelName = (id) =>
    systemDeviceModels?.find((model) => model.id === id)?.name

  const exportListConfig = {
    apiParams: deviceListApiParams,
    entity: 'devices',
  }

  const listActions = {
    bulk: [
      { action: openBulkSendCommand, title: 'Send Command' },
      { action: openBulkUpdate, title: 'Update Status' },
      { action: openBulkUnassign, title: 'Unassign' },
    ],
    create: () => setSimulatedDeviceFormOpen(true),
    update: openEditForm,
  }

  const columns = [
    {
      field: 'mainMac',
      headerName: 'Main MAC',
      flex: 1.5,
      minWidth: 190,
      renderCell: ({ row }) =>
        row.simulatedInterval ? (
          <Box display="flex" justifyContent="space-between" width="100%" pr="1rem">
            <ClickableCell
              label={row.mainMac}
              url={deviceUrls.entity.replace(':id', row.id)}
            />
            <Tooltip placement="right" title="This is a simulated device.">
              <AnimationIcon color="primary" />
            </Tooltip>
          </Box>
        ) : (
          <ClickableCell
            label={row.mainMac}
            url={deviceUrls.entity.replace(':id', row.id)}
          />
        ),
    },
    {
      field: 'modelName',
      sortField: 'model__name',
      headerName: 'Model',
      valueGetter: (_, row) => row.modelName.match(/\((.*)\)/).pop(),
      flex: 0.75,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: ({ row }) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            width: 'inherit',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <ListTextTooltipWrapper tooltip={row.status}>
            <Typography component="span">{row.status}</Typography>
          </ListTextTooltipWrapper>
          <br />
          <ListTextTooltipWrapper tooltip={row.healthStatus}>
            <Typography variant="caption">{row.healthStatus}</Typography>
          </ListTextTooltipWrapper>
        </div>
      ),
      renderMobile: ({ row }) => `${row.status} (${row.healthStatus})`,
    },
    ...(!renderAsTab
      ? [
          { field: 'uptime', headerName: 'Uptime', flex: 0.75 },
          { field: 'rssi', headerName: 'RSSI', flex: 0.75 },
        ]
      : []),
    {
      field: 'seenOn',
      headerName: 'Last Seen',
      flex: 1.5,
      valueGetter: (_, row) =>
        row.seenOn ? humanizedAgo(DateTime.fromISO(row.seenOn)) : '--',
    },
    ...(unitId || propertyId
      ? [
          {
            field: 'organizationName',
            sortField: 'organization__name',
            headerName: 'Organization',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.organizationName}
                url={orgUrls.entity.replace(':id', row.organization)}
              />
            ),
            flex: 1,
          },
          {
            field: 'zoneName',
            sortField: 'zone__name',
            headerName: 'Zone',
            flex: 1,
          },
          {
            field: 'firmwareVersion',
            headerName: 'Firmware Version',
            type: 'number',
            flex: 0.75,
          },
        ]
      : [
          {
            field: 'propertyName',
            sortField: 'zone__unit__property__name',
            headerName: 'Property',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.propertyName}
                url={propertyUrls.entity.replace(':id', row.propertyId)}
              />
            ),
            flex: 1.5,
          },
          {
            field: 'unitName',
            sortField: 'zone__unit__name',
            headerName: 'Unit',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.unitName}
                url={unitUrls.entity.replace(':id', row.unitId)}
              />
            ),
            flex: 1.5,
          },
        ]),

    {
      field: 'grafana',
      headerName: 'Grafana',
      flex: 0.6,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) => (
        <Typography
          onClick={() => {
            queryHolder.current = queryObject
            doUpdateQuery({ grafanaDevice: row.id }, { maintainScrollPosition: true })
          }}
          sx={{
            fontSize: isSmallScreen ? 12 : 14,
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#4840ba',
          }}
        >
          View
        </Typography>
      ),
    },
  ]

  const rows = deviceList?.results?.map((device) => ({
    id: device.id,
    mainMac: device.mainMac,
    model: device.model,
    modelName: getDeviceModelName(device.model),
    status: device.status,
    seenOn: device.seenOn,
    organization: device.organization,
    organizationName: device.organizationName,
    propertyId: device.propertyId,
    propertyName: device.propertyName,
    unitId: device.unitId,
    unitName: device.unitName,
    zone: device.zone,
    zoneName: device.zoneName,
    firmwareVersion: device.firmwareVersion,
    uptime: device.uptime ? `${parseFloat(device.uptime * 100).toFixed(2)}%` : '--',
    rssi: device.rssi ? `${parseFloat(device.rssi).toFixed(2)} dBm` : '--',
    healthStatus: systemDeviceHealthStatus[device.healthStatus],
    simulatedInterval: device.simulatedInterval,
    scenario: device.scenario,
  }))

  const MobileItemHeader = useCallback(
    ({ row }) => (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={deviceUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.mainMac}
          </Typography>
        </Link>
        {row.simulatedInterval && (
          <AnimationIcon sx={{ fontSize: 16, ml: 0.5 }} color="primary" />
        )}
      </Box>
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['mainMac'],
        multiRowFields: [['uptime', 'rssi']],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  if (!deviceList.results)
    return <ErrorComponent title="Devices" callback={handleClear} />

  return (
    <>
      <BulkPopover
        items={[
          { label: 'Mark as In Service', handler: handleBulkUpdate('OFFLINE') },
          { label: 'Mark as Failure', handler: handleBulkUpdate('FAILURE') },
          { label: 'Mark as RMA', handler: handleBulkUpdate('RMA') },
          {
            label: 'Mark as RMA Replacement Shipped',
            handler: handleBulkUpdate('RMA_REPLACEMENT_SHIPPED'),
          },
          { label: 'Mark as Retired', handler: handleBulkUpdate('RETIRED') },
          { label: 'Mark as Provisioned', handler: handleBulkUpdate('PROVISIONED') },
          { label: 'Mark as Installed', handler: handleBulkUpdate('INSTALLED') },
          {
            label: 'Mark as Awaiting Service',
            handler: handleBulkUpdate('AWAITING_SERVICE'),
          },
        ]}
        open={saveFormOpen}
        anchorEl={popoverLocation}
        onClose={handleClose}
      />
      <ConfirmationDialog
        title="Unassign Device"
        message={`Are you sure you want to unassign ${selectedIds.length} device(s)?`}
        open={unassignConfirmationOpen}
        onConfirm={handleUnassignDevice}
        onCancel={() => setUnassignConfirmationOpen(false)}
      />
      <CommandForm
        devices={selectedIds}
        open={commandFormOpen}
        onClose={() => {
          setSelectedIds([])
          doMarkDeviceListAsOutdated()
          setCommandFormOpen(false)
        }}
        onSave={doDeviceSendCommand}
      />
      <ReassignForm
        open={reassignFormOpen}
        onClose={() => {
          setCurrentDevice({})
          doMarkDeviceListAsOutdated()
          setReassignFormOpen(false)
        }}
        instance={currentDevice}
      />
      <SimulatedDeviceForm
        open={simulatedDeviceFormOpen}
        onClose={() => {
          setCurrentDevice({})
          doMarkDeviceListAsOutdated()
          setSimulatedDeviceFormOpen(false)
        }}
        instance={currentDevice}
      />
      <ThemeProvider theme={darkTheme}>
        <EventViewModal
          id={grafanaViewDevice}
          entity="device"
          onClose={() => {
            doUpdateQuery(queryHolder.current, { maintainScrollPosition: true })
            setGrafanaViewDevice(null)
            queryHolder.current = null
          }}
        />
      </ThemeProvider>
      <Box m={renderAsTab ? 0 : 3} sx={{ display: 'flex', flexDirection: 'column' }}>
        {!renderAsTab && (
          <>
            <Breadcrumbs
              links={[{ label: 'Home', href: homeUrls.home }, { label: 'Devices' }]}
            />
            <ListPageTitle
              title="Devices"
              onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
              menuItems={
                isSmallScreen
                  ? [
                      {
                        label: 'Add Simulated Device',
                        onClick: () => setSimulatedDeviceFormOpen(true),
                      },
                      ...(selectedIds?.length
                        ? [
                            { label: 'Send Command', onClick: openBulkSendCommand },
                            { label: 'Update Status', onClick: openBulkUpdate },
                            { label: 'Unassign', onClick: openBulkUnassign },
                          ]
                        : []),
                      {
                        label: 'Export',
                        onClick: () => {},
                        render: ({ onClose }) => (
                          <ListExportButton
                            mode="menuItem"
                            postExport={onClose}
                            {...exportListConfig}
                          />
                        ),
                      },
                    ]
                  : null
              }
              mb={2}
            />
          </>
        )}
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={deviceListIsLoading}
            apiParams={deviceListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
            {!renderAsTab && (
              <>
                <DynamicSelect
                  label="Organization Groups"
                  filterName="organization_group"
                />
                <DynamicSelect label="Organizations" filterName="organization" />
                <DynamicSelect label="Accounts" filterName="account" />
                <DynamicSelect label="Properties" filterName="property" />
                <DynamicSelect label="Property Groups" filterName="property_group" />
                <DynamicSelect label="Units" filterName="unit" />
                <DynamicSelect label="Unit Groups" filterName="unit_group" />
                <DynamicSelect label="Zones" filterName="zone" />
              </>
            )}
            <StaticSelect
              label="Device model"
              filterName="model"
              options={systemDeviceModels}
              getOptionLabel={(option) => getDeviceModelName(option.id)}
            />
            <StaticSelect
              label="Device Status"
              filterName="status"
              getOptionLabel={(option) =>
                option && capitalize(option.replace(/_/g, ' '))
              }
              options={systemDeviceStatus}
            />
            <StaticSelect
              label="Health Status"
              filterName="healthStatus"
              getOptionLabel={(option) => option && systemDeviceHealthStatus[option]}
              options={Object.keys(systemDeviceHealthStatus)}
            />
            <StaticSelect
              label="Simulated Device"
              filterName="simulated"
              options={boolOptions}
            />
            <StaticSelect
              label="Refurbished"
              filterName="refurbished"
              options={boolOptions}
            />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="1000px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title="Devices"
                loading={deviceListIsLoading}
                actions={renderAsTab ? {} : listActions}
                itemBuilder={MobileListItem}
                page={deviceList.current || 1}
                pageChange={handlePageChange}
                pageSize={deviceList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={deviceList.count || 0}
                rowClick={handleRowClick}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                title="Devices"
                loading={deviceListIsLoading}
                showAddButton={!renderAsTab}
                addButtonText="Add Simulated Device"
                exportListConfig={renderAsTab ? null : exportListConfig}
                actions={listActions}
                columns={columns}
                page={deviceList.current || 1}
                pageChange={handlePageChange}
                pageSize={deviceList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={deviceList.count || 0}
                rowClick={handleRowClick}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                sortChange={doDeviceListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

DeviceList.defaultProps = {
  renderAsTab: false,
  unitId: '',
  propertyId: '',
}

DeviceList.propTypes = {
  renderAsTab: PropTypes.bool,
  unitId: PropTypes.string,
  propertyId: PropTypes.string,
}
