import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Grid2 } from '@mui/material'

import { parseApiErrors } from '@common/utils'

import AutoChargeCard from './AutoChargeCard'
import EmailIngestCard from './EmailIngestCard'
import EmailLogCard from './EmailLogCard'
import ReviewIngestCard from './ReviewIngestCard'

export default function Integrations({ property }) {
  const [emailLogsOpen, setEmailLogIsOpen] = useState(false)
  const {
    isAtLeastAdmin,
    systemSmokeAutoChargeTypes,
    doPropertySave,
    doFetchPropertyDetails,
    doPropertyTestAutocharge,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectSystemSmokeAutoChargeTypes',
    'doPropertySave',
    'doFetchPropertyDetails',
    'doPropertyTestAutocharge',
    'doShowSnackbar',
  )

  const fetchPropertyDetails = useCallback(async () => {
    try {
      await doFetchPropertyDetails(property?.id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [property])

  return (
    <Grid2 container mt={5} gap={5}>
      <EmailLogCard open={emailLogsOpen} onClose={() => setEmailLogIsOpen(false)} />
      <Grid2>
        <AutoChargeCard
          property={property}
          chargeTypes={systemSmokeAutoChargeTypes}
          saveProperty={doPropertySave}
          showSnackBar={doShowSnackbar}
          fetchProperty={fetchPropertyDetails}
          testAutoCharge={doPropertyTestAutocharge}
          isAtLeastAdmin={isAtLeastAdmin}
        />
      </Grid2>
      <Grid2>
        <EmailIngestCard
          property={property}
          saveProperty={doPropertySave}
          fetchProperty={fetchPropertyDetails}
          showSnackBar={doShowSnackbar}
          isAtLeastAdmin={isAtLeastAdmin}
          setEmailLogIsOpen={setEmailLogIsOpen}
        />
      </Grid2>
      <Grid2>
        <ReviewIngestCard
          property={property}
          saveProperty={doPropertySave}
          fetchProperty={fetchPropertyDetails}
          showSnackBar={doShowSnackbar}
          isAtLeastAdmin={isAtLeastAdmin}
        />
      </Grid2>
    </Grid2>
  )
}

Integrations.defaultProps = {
  property: {},
}

Integrations.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    emailIngestionIdentifier: PropTypes.string,
    smokeAutocharge: PropTypes.string,
    lastReservationEmailTs: PropTypes.string,
    reservationEmailCadence: PropTypes.string,
  }),
}
