import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { Grid2, Link, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

export default function DetailItem({
  label,
  labelIcon,
  value,
  renderValue,
  linkTo,
  titleSx,
  sx,
}) {
  const isSmallScreen = useSmallScreen()

  return (
    <Grid2>
      <Typography
        variant="body2"
        color="text.secondary"
        textTransform="uppercase"
        sx={{ fontSize: isSmallScreen ? 10 : 14, ...titleSx }}
        display="flex"
        alignItems="center"
        gap={0.5}
      >
        {label} {labelIcon && labelIcon}
      </Typography>
      {!!renderValue && renderValue(value)}
      {(!!renderValue && !isEmpty(value)) ||
        (linkTo?.length ? (
          <Link href={linkTo}>
            <Typography
              variant="h6"
              whiteSpace="pre-line"
              sx={{ fontSize: isSmallScreen ? 14 : 20, ...sx }}
            >
              {value || 'N/A'}
            </Typography>
          </Link>
        ) : (
          <Typography
            variant="h6"
            whiteSpace="pre-line"
            sx={{ fontSize: isSmallScreen ? 14 : 20, ...sx }}
          >
            {value || 'N/A'}
          </Typography>
        ))}
    </Grid2>
  )
}

DetailItem.defaultProps = {
  value: '',
  labelIcon: undefined,
  linkTo: '',
  renderValue: undefined,
  sx: {},
  titleSx: {},
}

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  labelIcon: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  linkTo: PropTypes.string,
  renderValue: PropTypes.func,
  sx: PropTypes.shape({}),
  titleSx: PropTypes.shape({}),
}
